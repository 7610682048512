import React, { useState, useEffect } from "react";
import "./App.css";
import se from "./sound/kitchen_timer.mp3";
import useSound from "use-sound";

let timer = 1000;
let wakeLock = null;
let random = 1;

function App() {
  const [hour, setHour] = useState(0);
  const [minute, setMinute] = useState(0);
  const [second, setSecond] = useState(0);

  const [isRunning, setIsRunning] = useState(false);

  const [play, { sound, stop }] = useSound(se);
  const [isRing, setIsRing] = useState(false);

  useEffect(() => {
    // 終了したときの音の制御がうまいこといかない
    const interval = setInterval(async () => {
      if (isRunning) {
        coundDown();
        randomTimer();
      }
    }, timer);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hour, minute, second, isRunning]);

  const coundDown = () => {
    if (downSecond()) {
      if (downMinute()) {
        downHour();
      }
    }
  };

  const downSecond = () => {
    if (second !== 0) {
      setSecond((prev) => prev - 1);
      return false;
    } else if ((hour !== 0 || minute !== 0) && second === 0) {
      setSecond(59);
      return true;
    } else {
      finish();
      return false;
    }
  };

  const downMinute = () => {
    if (minute !== 0) {
      setMinute((prev) => prev - 1);
      return false;
    } else if (hour !== 0 && minute === 0) {
      setMinute(59);
      return true;
    } else {
      return false;
    }
  };

  const downHour = () => {
    if (hour === 0) {
      return;
    }
    setHour((prev) => prev - 1);
  };

  const randomTimer = () => {
    switch (random) {
      case 1:
        timer = Math.floor(Math.random() * (1500 - 500) + 500);
        break;
      case 2:
        timer = Math.floor(Math.random() * (1500 - 800) + 800);
        break;
      case 3:
        timer = Math.floor(Math.random() * (1200 - 500) + 500);

        break;
      default:
    }
  };

  const finish = () => {
    setIsRunning(false);
    setIsRing(true);
    sound.loop(true);
    play();
    document.getElementById("image").classList.add("image");
  };

  const startTimer = async (startDiv) => {
    // 数値以外が含まれている場合はエラーにする
    if (isNumber()) {
      // 設定時間は何でもよくて100分とかでもOK
      // 先頭に0がある場合は外す
      setHour(Number(document.getElementById("hour").innerHTML));
      setMinute(Number(document.getElementById("minute").innerHTML));
      setSecond(Number(document.getElementById("second").innerHTML));
      // 時間がセットされていること
      if (Number(document.getElementById("hour").innerHTML) === 0 && Number(document.getElementById("minute").innerHTML) === 0 && Number(document.getElementById("second").innerHTML) === 0) {
        alert("タイマーをセットしてください。");
      } else {
        try {
          wakeLock = await navigator.wakeLock.request("screen");
        } catch (err) {
          alert("エラーが発生しました。しばらくしてから再実行してください。");
        }
        setIsRunning(true);
        random = startDiv;
      }
    } else {
      alert("タイマーには半角数字のみを入力してください。");
    }
  };

  const isNumber = () => {
    const pattern = /^\d+$/;
    return pattern.test(document.getElementById("hour").innerHTML) && pattern.test(document.getElementById("minute").innerHTML) && pattern.test(document.getElementById("second").innerHTML);
  };

  const stopTimer = () => {
    if (wakeLock != null) {
      wakeLock.release().then(() => {
        wakeLock = null;
      });
    }
    setIsRunning(false);
  };

  const resetTimer = () => {
    if (wakeLock != null) {
      wakeLock.release().then(() => {
        wakeLock = null;
      });
    }
    setHour(0);
    setMinute(0);
    setSecond(0);
    setIsRunning(false);
  };

  const stopSound = () => {
    if (wakeLock != null) {
      wakeLock.release().then(() => {
        wakeLock = null;
      });
      sound.loop(false);
      stop();
      setIsRing(false);
      document.getElementById("image").classList.remove("image");
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <img id="image" className="" alt="logo" onClick={stopSound} src={`${process.env.PUBLIC_URL}/daitai_timer2.png`}></img>
        <p>だいたいタイマー</p>
        <div className="flex">
          <div className="timerDiv" contentEditable={!isRunning} suppressContentEditableWarning>
            <p id="hour">{("00" + hour).slice(-2)}</p>
          </div>
          <div className="tani">時間</div>
          <div className="timerDiv" contentEditable={!isRunning} suppressContentEditableWarning>
            <p id="minute">{("00" + minute).slice(-2)}</p>
          </div>
          <div className="tani">分</div>
          <div className="timerDiv" contentEditable={!isRunning} suppressContentEditableWarning>
            <p id="second">{("00" + second).slice(-2)}</p>
          </div>
          <div className="tani">秒</div>
        </div>
        <div className="buttonList">
          {!isRunning && !isRing && (
            <div>
              <button className="button" onClick={() => startTimer(3)}>
                早め
              </button>
              <button className="button" onClick={() => startTimer(1)}>
                普通
              </button>
              <button className="button" onClick={() => startTimer(2)}>
                遅め
              </button>
            </div>
          )}
          {isRunning && (
            <div>
              <button className="button" onClick={stopTimer}>
                ストップ
              </button>
              <button className="button" onClick={resetTimer}>
                リセット
              </button>
            </div>
          )}
        </div>
        <div className="buttonList"></div>
      </header>
      <footer className="App-footer">
        <a className="simpleLink" href="poricy">
          プライバシーポリシー
        </a>
        <a className="simpleLink" href="https://forms.gle/kBNTcrojJq8tau5f9" target="_blank" rel="noopener noreferrer">
          お問い合わせ
        </a>
      </footer>
    </div>
  );
}

export default App;
